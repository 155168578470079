import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
import ButtonWrapper from "../components/Button"
import Footer from "../components/Footer"
import CallToAction from "../components/CallToAction"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"

const PageWrapper = styled.div`
  margin: 80px 0;
  .rowContent {
    margin-top: 50px;
    text-align: center;
  }
  h1 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 38px;
    color: #323232;
    margin-bottom: 20px;
  }
  .companyHtml {
    max-width: 800px;
    margin: 0 auto;
    font-family: "Prompt", sans-serif;
    color: #323232;
    margin-bottom: 30px;
    img {
      width: 100%;
      max-width: 800px;
      margin: 20px auto;
    }
  }
  .address {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #848383;
    margin-bottom: 20px;
  }
  .phoneText {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #323232;
    margin-bottom: 5px;
  }
  .telText {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 22px;
    color: #848383;
    margin-bottom: 0;
    a {
      color: #848383;
      text-decoration: none;
    }
  }
  .divWrapper {
    margin-top: 20px;
  }
`

const CompanyProfilePage = () => {
  const { pageData } = useStaticQuery(
    graphql`
      query {
        pageData: allContentfulCompanyProfile {
          edges {
            node {
              title
              company {
                company
              }
              address {
                address
              }
              phoneText
              heroImage {
                fluid(maxWidth: 1400) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    title,
    company,
    address,
    phoneText,
    heroImage,
  } = pageData.edges[0].node

  return (
    <Layout>
      <SEO
        title="ติดต่อสั่งซื้อ | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์"
        description="ติดต่อสั่งซื้อ | AEC brand | ปั๊มหอยโข่งโซล่าเซลล์ ปั๊มบาดาลโซล่าเซลล์ ปั๊มโซล่าเซลล์ ระบบ Off Grid โซล่าเซลล์ สำหรับใช้กับไฟบ้าน และเพื่อการเกษตร"
      />
      <HeaderTop text={options.headerTop} />
      <HeaderColor />
      <PageWrapper>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <Img fluid={heroImage.fluid} loading="eager" fadeIn={false} />
            </Col>
          </Row>
          <Row className="rowContent">
            <Col span={24}>
              <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
              <div className="companyHtml">
                <ReactMarkdown
                  plugins={[gfm]}
                  children={company.company}
                  allowDangerousHtml={true}
                />
              </div>
              <div
                className="address"
                dangerouslySetInnerHTML={{ __html: address.address }}
              ></div>
              <p
                className="phoneText"
                dangerouslySetInnerHTML={{ __html: phoneText }}
              ></p>
              <p className="telText">
                <a href={options.telCTA} target="_blank" rel="noreferrer">
                  {options.telText}
                </a>
              </p>
              <div className="divWrapper">
                <ButtonWrapper
                  text={options.lineText}
                  cta={options.lineCTA}
                  path="target"
                />
              </div>
            </Col>
          </Row>
        </div>
      </PageWrapper>
      <Footer />
      <CallToAction />
    </Layout>
  )
}

export default CompanyProfilePage
